import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ordersService } from "../../../../../api/services/orders";
import {
  IOngoingOrders,
  IRiderAccepted,
  IOrderChanged,
  OrderFlagType,
  OrderWebSocketEvents,
} from "../../../../../api/types/orders";
import HorizontalLoading from "../../../../../components/app/HorizontalLoading";
import { NotFoundResult } from "../../../../../components/app/NotFoundResult";
import PageContentWrapper from "../../../../../components/app/PageContentWrapepr";
import useSignalR from "../../../../../hooks/useSignalR";
import {
  useOrderDataDispatch,
  useOrderDataState,
} from "../../OrdersDataContext";
import { OrdersDataStoreActionKind } from "../../OrdersDataStore/OrdersDataStore-Actions";
import OngoingOrderItem from "../OngoingOrderItem";
import { HubConnectionState } from "@microsoft/signalr";
import AcceptedRiderModal from "../AcceptedRiderModal";
import CancelOrderConfirmationModal from "../CancelOrderConfirmationModal";
import dateHelper from "../../../../../utils/date-helper";
import CreateNewOrder from "../CreateNewOrder";
import { useGlobal } from "../../../../../context/GlobalContext";
import { USER_ROLE } from "../../../../../types/user";

const OngoingOrderList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useOrderDataDispatch();
  const { orders } = useOrderDataState();
  const { user } = useGlobal();

  const [getAbortController] = useState<AbortController>(new AbortController());
  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenAcceptedRiderModal, setIsOpenAcceptedRiderModal] =
    useState<boolean>(false);
  const [
    isOpenCancelOrderConfirmationModal,
    setOpenCancelOrderConfirmationModal,
  ] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<IOngoingOrders>(
    {} as IOngoingOrders
  );
  let fetchedOrderList = useRef<boolean>(false);
  let setTimeoutId: ReturnType<typeof setTimeout>;

  const { connection, connectionStatus } = useSignalR({
    urlPath: "hub/notificationhub",
  });

  const memoizedOrderItems = useMemo(() => {
    return orders.map((order) => ({ ...order, status: order.status }));
  }, [orders]);

  const handleOrdreStartedEvent = () => {
    connection?.on(OrderWebSocketEvents.OrderStarted, (order: string) => {
      const orderJSON: IOngoingOrders = JSON.parse(order);
      orderJSON.flag = OrderFlagType.ADDED;
      setShowNoResultFound(false);
      dispatch({
        type: OrdersDataStoreActionKind.ADD_NEW_ORDER,
        data: { newOrder: orderJSON },
      });
    });
  };

  const handleRiderAcceptedEvent = () => {
    connection?.on(OrderWebSocketEvents.RiderAccepted, (order: string) => {
      const acceptedOrder: IRiderAccepted = JSON.parse(order);
      dispatch({
        type: OrdersDataStoreActionKind.ACCEPT_ORDER,
        data: {
          id: acceptedOrder.id,
          status: acceptedOrder.status,
          availableActions: acceptedOrder.availableActions,
          histories: acceptedOrder.histories,
          acceptedRiders: acceptedOrder.acceptedRiders || [],
        },
      });
    });
  };

  const handleOrderStatusChanged = () => {
    connection?.on(OrderWebSocketEvents.OrderStatusChanged, (order: string) => {
      const changedOrder: IOrderChanged = JSON.parse(order);
      dispatch({
        type: OrdersDataStoreActionKind.UPDATE_ORDER,
        data: {
          id: changedOrder.id,
          status: changedOrder.status,
          availableActions: changedOrder.availableActions,
          histories: changedOrder.histories,
          onTheWayDuration: changedOrder.onTheWayDuration,
        },
      });
    });
  };

  const getOrderList = useCallback(async () => {
    const last24HoursPeriod = dateHelper.getStartEndByDatesAndTimes(
      new Date().toString(),
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toString()
    );
    setLoading(true);
    try {
      const result = await ordersService.getOngoingOrders(
        last24HoursPeriod.end,
        last24HoursPeriod.start,
        getAbortController.signal
      );
      dispatch({
        type: OrdersDataStoreActionKind.SET_ORDERS,
        data: { orders: result.data.data.items },
      });
      result.data.data.items.length > 0
        ? setShowNoResultFound(false)
        : setShowNoResultFound(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [getAbortController]);

  useEffect(() => {
    fetchedOrderList.current && getOrderList();
  }, [getOrderList]);

  useEffect(() => {
    return () => {
      getAbortController.abort();
      clearTimeout(setTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (connectionStatus === HubConnectionState.Connected) {
      fetchedOrderList.current = true;
      getOrderList();
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connection) {
      handleOrdreStartedEvent();
      handleRiderAcceptedEvent();
      handleOrderStatusChanged();
    }
  }, [connection]);

  const handleCancelOrderConfirmation = (canceledOrder: IOngoingOrders) => {
    // console.log(`Canceled Order: ${canceledOrder}`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{t("titles.pagingOrders")}</Typography>
        {user?.role === USER_ROLE.ZoneAgent && <CreateNewOrder />}
      </Box>
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      <PageContentWrapper>
        <TableContainer>
          {!showNoResultFound ? (
            <Table
              sx={{ backgroundColor: "#fff", border: "1px solid #E6E6E6" }}
            >
              <TableHead sx={{ backgroundColor: "#E3E3E3" }}>
                <TableRow sx={{ borderRadius: "8px 8px 0 0 " }}>
                  <TableCell>{t("fields.orderID")}</TableCell>
                  <TableCell>{t("fields.OTP")}</TableCell>
                  <TableCell>{t("fields.orderValue")} (KD)</TableCell>
                  {user?.role === USER_ROLE.GroupAgent && (
                    <TableCell>{t("fields.zone")}</TableCell>
                  )}
                  <TableCell>{t("fields.pending")}</TableCell>
                  <TableCell>{t("fields.accepted")}</TableCell>
                  <TableCell>{t("fields.pickedUp")}</TableCell>
                  <TableCell>{t("fields.onTheWay")}</TableCell>
                  <TableCell>{t("fields.delivered")}</TableCell>
                  <TableCell>{t("fields.vehicleType")}</TableCell>
                  <TableCell>{t("fields.action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {memoizedOrderItems.map((orderItem) => {
                  return (
                    <OngoingOrderItem
                      key={orderItem.id}
                      orderItem={orderItem}
                      setIsOpenAcceptedRiderModal={setIsOpenAcceptedRiderModal}
                      setOpenCancelOrderConfirmationModal={
                        setOpenCancelOrderConfirmationModal
                      }
                      setSelectedOrder={setSelectedOrder}
                    />
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <NotFoundResult iconMaxWidth={250} />
          )}
        </TableContainer>
      </PageContentWrapper>
      {/* Accepted Modal */}
      <AcceptedRiderModal
        isOpen={isOpenAcceptedRiderModal}
        handleClose={() => setIsOpenAcceptedRiderModal(false)}
        orderItem={selectedOrder}
      />

      {/* CancelOrder Confirmation Modal */}
      <CancelOrderConfirmationModal
        isOpen={isOpenCancelOrderConfirmationModal}
        handleClose={() => setOpenCancelOrderConfirmationModal(false)}
        orderItem={selectedOrder}
        handleConfirmCancel={handleCancelOrderConfirmation}
      />
    </>
  );
};

export default OngoingOrderList;
