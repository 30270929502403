import {
  IOngoingOrders,
  OrderFlagType,
  TOrderStatus,
  IOrderStatusEnum,
} from "../../../../api/types/orders";
import {
  OrdersDataStoreAction,
  OrdersDataStoreActionKind,
} from "./OrdersDataStore-Actions";

export interface IOrdersDataStoreInitialState {
  orders: IOngoingOrders[];
  updatedOrder: IOngoingOrders;
}

export const ordersDataStoreInitialState: IOrdersDataStoreInitialState = {
  orders: [] as IOngoingOrders[],
  updatedOrder: {} as IOngoingOrders,
};

export const ordersDataStoreReducer = (
  state: typeof ordersDataStoreInitialState = ordersDataStoreInitialState,
  action: OrdersDataStoreAction<any>
) => {
  const { type, data } = action;
  const orderList = [...state.orders];

  switch (type) {
    case OrdersDataStoreActionKind.SET_ORDERS:
      return {
        ...state,
        orders: data.orders,
      };
    case OrdersDataStoreActionKind.ADD_NEW_ORDER:
      const existedOrderIndex = orderList.findIndex(
        (item) => item.id === data.newOrder.id
      );
      if (existedOrderIndex !== -1) {
        orderList[existedOrderIndex] = data.newOrder;
      } else {
        orderList.unshift(data.newOrder);
      }
      // we calculate onTheWayDueation of the orders that has PickedUp state after create new order
      orderList.map((orderItem) => {
        if (orderItem.status === IOrderStatusEnum.PickedUp) {
          let pickedUpHistoryItem = orderItem.histories.find(
            (item) => item.status === IOrderStatusEnum.PickedUp
          );
          orderItem.onTheWayDuration = Math.floor(
            (Date.now() - Date.parse(pickedUpHistoryItem?.date || "")) / 1000
          );
        }
        return orderItem;
      });

      return {
        ...state,
        orders: orderList,
      };
    case OrdersDataStoreActionKind.ACCEPT_ORDER:
      const acceptedOrder = orderList.find((order) => order.id === data.id);
      if (acceptedOrder) {
        acceptedOrder.availableActions = data?.availableActions || [];
        acceptedOrder.status = data.status as TOrderStatus;
        acceptedOrder.histories = data.histories;
        acceptedOrder.acceptedRiders = data.acceptedRiders;
        acceptedOrder.flag = OrderFlagType.UPDATED;
      }

      return {
        ...state,
        orders: orderList,
      };
    case OrdersDataStoreActionKind.UPDATE_ORDER:
      const updatedOrder = orderList.find((order) => order.id === data.id);
      if (updatedOrder) {
        updatedOrder.availableActions = data?.availableActions || [];
        updatedOrder.status = data.status as TOrderStatus;
        updatedOrder.histories = data.histories;
        updatedOrder.onTheWayDuration = data.onTheWayDuration;
      }

      return {
        ...state,
        orders: orderList,
      };
  }
};
